<template>
  <div class="orders-bg">
    <div class="orders-container container px-0">
      <div class="orders-top-content">
        <Menu></Menu>
      </div>

      <div class="orders-content px-5 py-4">
        <div class="orders-content-topbar pb-4">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-6 text-start">
              <span
                class="last-week p-3 ps-5 text-dark d-inline-block"
                @click="previousWeek()"
                >{{ $t('orders.title.previous_week') }}</span
              >
              <div class="d-block d-sm-inline ps-4 topbar-dates">
                <span class="fw-bold fs-5">{{
                  weekStart | moment("DD.MM.YYYY")
                }}</span>
                <span class="dates-slash fs-3">/</span>
                <span class="fw-bold fs-5">{{
                  weekEnd | moment("DD.MM.YYYY")
                }}</span>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 text-start text-sm-end">
              <div class="d-block d-sm-inline">

          
          <router-link :to="{ name: 'orders_new_month' }" style="background-color: #f3f3f3 !important;" class="d-inline-block px-3 py-2 orders-new-month-calendar-button">
            <img
              src="../../../assets/img/calendar.png"
              alt="/"
              style="width: 30px; height: auto;"
              class="d-inline-block py-1"
            /><p class="d-inline-block mb-0 ps-3 text-body">{{ $t('orders.title.month') }}</p>
          </router-link>   

                <date-picker
                  v-model="dateStart"
                  :clearable="false"
                  format="DD.MM.YYYY"
                  valueType="format"
                  class="mx-3"
                >
                  <i slot="icon-calendar" class="date-picker-input-arrow"></i>
                  <i slot="icon-clear" class="date-picker-input-clear"></i>
                </date-picker>
              </div>

              <span
                class="next-week p-3 pe-5 text-dark d-inline-block"
                @click="nextWeek()"
                >{{ $t('orders.title.next_week') }}</span
              >
            </div>
          </div>
        </div>

        <div class="week-content pt-3">
          <div class="one-day">
            <div class="day-meal">

              <div class="auction-section" v-if="auctionItems && auctionItems.length > 0">

                  <div class="auction-container d-flex flex-column flex-md-row justify-content-between align-items-center" v-b-toggle.auction-items>
                      <span v-html="$t('orders.title.num_of_items_on_auction', {'item': auctionItems.length})"></span>
                      <b-button variant="primary">{{ $t('orders.title.show_all_orders_on_auction') }}</b-button>
                  </div>

                  <b-collapse id="auction-items" class="mt-1 mb-4">
                    <b-card>
                      <div class="day-meal-table" v-for="(auctionItem, index) in auctionItems" :key="index">
                        <div
                            class="
                              left-section
                              col-10
                              d-flex
                              align-items-center
                              gap-2 gap-sm-4
                              my-2
                            "
                          >
                            <div>
                                    <span class="meal-checkbox" @click="buyOrder(auctionItem.order_id)" v-b-tooltip.right='$t("orders.title.order_buy_from_auction")'></span>
                              </div>
                              <div>
                                    <span :class="['mx-2 fw-light text-white number number-' + auctionItem.meal_menu_number]"><p>{{auctionItem.meal_menu_number}}</p></span>
                                    <p class="d-inline">{{auctionItem.name}}</p>
                              </div>
                            </div>
                        </div>
                      </b-card>
                    </b-collapse>
                  </div> 
                </div>
              </div>


            <div v-if="mealMenu && mealMenu.length > 0">
                  <div v-for="(days, index) in mealMenu" :key="index">
                    <div class="one-day pb-4">
                    <div class="day-heading px-3 py-2 text-white fs-6">
                      <span class="fw-bold">{{ days.date | moment("DD.MM.YYYY") }}</span>
                      <span class="ps-1 pe-1 fs-6 fw-light">/</span>
                      <span class="fw-light capitalize">{{ days.date | moment("dddd") }}</span>
                    </div>
                    <div class="day-meal">

                      <div v-if="days.types && days.types.length > 0">
                        <div v-for="(day, index) in days.types" :key="index">
                          <div class="day-meal-heading row py-3">
                            <p class="col-6 text-start mb-0">{{day.name}}:</p>
                            <p class="col-6 text-end mb-0">{{ $t('orders.title.alergens') }}</p>
                          </div>
                     
                         <div v-if="day.meals && day.meals.length > 0">
                            <div class="day-meal-table" v-for="(meal, index) in day.meals" :key="index">
                              <div class="row pb-3">
                                <div
                                  class="
                                    left-section
                                    col-10
                                    d-flex
                                    align-items-center
                                    gap-2 gap-sm-4
                                  "
                                >
                                  <div>
                                    
                                    <!-- Neexistující objednávka -->
                                    <span v-if="meal.order == null">
                                        <!-- Můžeme objednat novou -->
                                        <span v-if="meal.can_be_ordered == 1">
                                          <span class="meal-checkbox" @click="makeOrder(meal.item_id)" v-b-tooltip.right='$t("orders.title.create_order")'></span>
                                        </span>

                                        <!-- Již není možné objednat objednávku -->
                                        <span v-if="meal.can_be_ordered == 0">
                                          <span class="meal-checkbox meal-checkbox-none" v-b-tooltip.right='$t("orders.title.order_cant_ordered")'></span>
                                        </span>
                                    </span>

                                    <!-- Existuje objednávka -->
                                    <span v-else>
                                        <!-- Můžeme zrušit objednávku -->
                                        <div v-if="meal.order.can_be_canceled == 1">
                                            <span class="meal-checkbox meal-checkbox-active" v-b-tooltip.right='$t("orders.title.order_cancel")'  @click="cancelOrder(meal.item_id, meal.order.order_id)"></span>
                                        </div>

                                        <!-- Můžeme prodat na burzu objednávku -->
                                        <div v-if="meal.order.can_be_sold == 1">
                                            <span class="meal-checkbox meal-checkbox-sell" v-b-tooltip.right='$t("orders.title.order_sell")'  @click="sellOrder(meal.order.order_id)"></span>
                                        </div>

                                        <!-- Objednávka nelze prodat ani vložit na burzu -->
                                        <span v-if="meal.order.can_be_canceled == 0 && meal.order.can_be_sold == 0">
                                          <!-- Objednaná strava -->
                                          <span class="meal-checkbox meal-checkbox-active-sold-waiting" v-if="meal.order.state == 'AUCTION'" v-b-tooltip.right='$t("orders.title.order_sold_waiting")'></span>

                                          <!-- Strava čeká na prodej na burze -->
                                          <span class="meal-checkbox meal-checkbox-active-past" v-else  v-b-tooltip.right='$t("orders.title.order_ordered")'></span>
                                        </span>

                                    </span>

                                  </div>
                                  <div>
                                    <span :class="['fs-6 fw-light text-white number number-' + meal.meal_menu_number]"><p>{{meal.meal_menu_number}}</p></span>
                                    <p class="d-inline ms-3">{{meal.name}}</p>
                                  </div>
                                </div>
                                   <div class="
                                      col-1 
                                      d-flex
                                      align-items-center"
                                    >
                                   
                                </div>
                                <div
                                  class="
                                    right-section
                                    col-1
                                    d-flex
                                    align-items-center
                                    justify-content-end
                                  "
                                >
                                  <p class="text-right mb-0">
                                    <span v-if="meal.allergens_number_string != ''">{{meal.allergens_number_string}}</span>
                                    <span v-else class="text-">-</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else class="text-muted">
                             {{ $t('orders.title.no_variant_exists') }}
                          </div>
                        </div>
                      </div>
                      <div v-else class="text-muted mt-4">
                        {{ $t('orders.title.no_food_type_exists') }}
                      </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
     

        <div class="orders-content-bottom pt-5">
          <div class="top-section pb-3 mb-3">
            <span class="d-flex align-items-center justify-content-start gap-3"
              ><img
                style="width: 22px"
                src="../../../assets/img/information-button.png"
                alt=""
              />
              <p class="mb-0">{{ $t('orders.title.help') }}:</p></span
            >
          </div>

          <div class="bottom-section">
            <div class="row">
              <div class="col-12 col-sm-4">
                <div class="d-flex align-items-center gap-3">
                  <span class="meal-checkbox meal-checkbox-active"></span>
                  <p class="mb-0">{{ $t('orders.title.help_ordered_variant') }}</p>
                </div>

                <div class="d-flex align-items-center gap-3 mt-3">
                  <span class="meal-checkbox"></span>
                  <p class="mb-0">{{ $t('orders.title.help_variant_to_order') }}</p>
                </div>
              </div>
              <div class="col-12 col-sm-4">
                <div class="mt-2">
                  <p class="mb-0" v-html="$t('orders.title.help_variant_to_cancel_order')">
                    
                  </p>
                </div>
              </div>
              <div
                class="
                  col-12 col-sm-4
                  d-flex
                  align-items-center
                  justify-content-sm-end
                "
              >
                <span
                  style="text-decoration: none; cursor: pointer;"
                  class="fw-bold text-dark mt-3 meal-menu-pdf"
                  href="/"
                  @click="downloadMealToPDF()"
                  ref="download_pdf_mealmenu"
                  ><img
                    width="30"
                    src="../../../assets/img/pdf.png"
                    alt=""
                  />
                  <span class="ms-2">{{ $t('orders.title.download_pdf_mealmenu') }}</span>
                  </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import { checkErrors } from "@/utils/api-errors";
import i18n from "@/plugins/vue-i18n";
import moment from "@/plugins/moment";
import Menu from "@/views/core/Menu";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/cs";

export default {
  metaInfo() {
    return { title: i18n.t("meta.orders_new") };
  },
  data() {
    return {
      mealMenu: null,
      auctionItems: null,
      dateStart: moment(moment()).format("DD.MM.YYYY"),
      actualWeek: moment(moment()).format("W"),
      actualYear: moment(moment(this.dateStart)).format("YYYY"),
      weekStart: moment(moment(this.dateStart).weekday(0)).format("DD.MM.YYYY"),
      weekEnd: moment(moment(this.dateStart).weekday(4)).format("DD.MM.YYYY"),
    };
  },
  watch: {
    dateStart: function () {
      this.weekStart = moment(
        moment(
          moment(this.dateStart, "DD.MM.YYYY").format("YYYY-MM-DD")
        ).weekday(0)
      ).format("DD.MM.YYYY");
      (this.weekEnd = moment(
        moment(
          moment(this.dateStart, "DD.MM.YYYY").format("YYYY-MM-DD")
        ).weekday(4)
      ).format("DD.MM.YYYY")),
        (this.actualWeek = moment(
          moment(moment(this.dateStart, "DD.MM.YYYY").format("YYYY-MM-DD"))
        ).format("W")),
        (this.actualYear = moment(
          moment(moment(this.dateStart, "DD.MM.YYYY").format("YYYY-MM-DD"))
        ).format("YYYY")),
        this.getMealMenu();
        this.getAuctionItems();
    },
  },
  components: {
    DatePicker,
    Menu,
  },
  mounted() {
    this.getLastSearch();
    this.getMealMenu();
    this.getAuctionItems();
  },
  computed: {
    orderNewSearch: {
      get () {
        return this.$store.state.orders.orderNewSearch;
      },
      set (value) {
        this.$store.commit('setOrderNewSearch', value);
      }
    }
  },
  methods: {
    getMealMenu() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "diner/meal-menu/week?week_number=" +
          this.actualWeek +
          "&year=" +
          this.actualYear
      ).then((response) => {
        this.mealMenu = response.data;
        this.loading = false;
      });
    },
    getAuctionItems(){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "orders/auction?from=" + moment(this.weekStart, "DD.MM.YYYY").format("YYYY-MM-DD") + "&to=" + moment(this.weekEnd, "DD.MM.YYYY").format("YYYY-MM-DD")).then((response) => {
        this.auctionItems = response.data.data;
        this.loading = false;
      });
    },
    previousWeek() {
      this.dateStart = moment(
        moment(
          moment(this.dateStart, "DD.MM.YYYY")
            .subtract(1, "weeks")
            .format("YYYY-MM-DD")
        ).weekday(1)
      ).format("DD.MM.YYYY");

      this.orderNewSearch = {
        dayStart: this.dateStart
      };
    },
    nextWeek() {
      this.dateStart = moment(
        moment(
          moment(this.dateStart, "DD.MM.YYYY")
            .add(1, "weeks")
            .format("YYYY-MM-DD")
        ).weekday(1)
      ).format("DD.MM.YYYY");

      this.orderNewSearch = {
        dayStart: this.dateStart
      };
    },
    downloadMealToPDF()
    {
       const pdfButton = this.$refs["download_pdf_mealmenu"];
       pdfButton.classList.add("meal-menu-pdf-loading");

       ApiService.apiDownloadFile("/diner/meal-menu/week/generate-pdf?week_number="+this.actualWeek+"&year="+this.actualYear).then(() => {
         pdfButton.classList.remove("meal-menu-pdf-loading");
       });
    },
    cancelOrder(meal_menu_id, order_id)
    {
      ApiService.setHeader();      
      ApiService.apiPost("/diner/orders/" + order_id + "/cancel", {
        meal_menu_id: meal_menu_id
      })
        .then(() => {
            this.loading = false;
            this.getMealMenu();
        }).catch(error => {
            checkErrors(error);
      });
    },
    makeOrder(meal_menu_id)
    {
      ApiService.setHeader();      
      ApiService.apiPost("/diner/orders", {
        meal_menu_id: meal_menu_id
      })
        .then(() => {
            this.loading = false;
            this.getMealMenu();
        }).catch(error => {
            checkErrors(error);
      });
    },
    sellOrder(order_id)
    {
      ApiService.setHeader();      
      ApiService.apiPost("/diner/orders/" + order_id + "/sell")
        .then(() => {
            this.loading = false;
            this.getMealMenu();
        }).catch(error => {
            checkErrors(error);
      });
    },
    buyOrder(order_id)
    {
      ApiService.setHeader();      
      ApiService.apiPost("/diner/orders/" + order_id + "/buy")
        .then(() => {
            this.loading = false;
            this.getMealMenu();
        }).catch(error => {
            checkErrors(error);
      });
    },
    getLastSearch()
    {
      let lastSearch = this.$store.state.orders.orderNewSearch;
      if(lastSearch.dayStart != null)
      {
        this.dateStart = lastSearch.dayStart;
      }
    }
  },
};
</script>
